type SeatDetails = {
  columnId: string;
  price: number;
  isAvailable: boolean;
  seatCharacteristics: string[];
  isFree: boolean;
  isRefundable: boolean;
  seatCoordinates: { xcoordinate: number; ycoordinate: number };
  seatNumber: string;
  paxTypes: string[];
};

interface Meal {
  code: string;
  description: string;
  mealType: "Veg" | "Non-Veg";
  paxTypeList: string[];
  price: number;
  refundable: boolean;
}

interface Baggage {
  baggageCode: string;
  baggageList: any[];
  baggageType: "CHECKIN_BAGGAGE";
  description: string;
  displayDetail: string;
  isInclusiveFreeBaggage: boolean;
  isRefundable: boolean;
  paxTypes: string[];
  price: number;
}

type Row = {
  rowCharacteristics: string;
  rowId: string;
  seatDetails: SeatDetails[];
};

export function getSortedUniquePrices(rows: Array<Row[]>): { 
  price: any; 
  order: number; 
  primaryColor: string; 
  secondaryColor: string; 
  displayPrice: string 
}[] {
  let prices = rows?.[0]?.flatMap(row => row?.seatDetails?.map(seat => seat?.price));

  prices?.sort((a, b) => a - b);

  const colorMappings = [
    { primaryColor: "#DEFBE4", secondaryColor: "#215039" },
    { primaryColor: "#E6EEFF", secondaryColor: "#13387E" },
    { primaryColor: "#6F8DDB", secondaryColor: "#E6EEFF" },
    { primaryColor: "#FFEACE", secondaryColor: "#8E3214" },
    { primaryColor: "#F0E2FF", secondaryColor: "#6B1299" },
    { primaryColor: "#6B1299", secondaryColor: "#F0E2FF" }
  ];

  prices = [...new Set(prices)];

  let sortedUniquePrices = prices?.map((price, index) => ({
    price,
    order: index,
    ...colorMappings[Math.min(index, colorMappings.length - 1)],
    displayPrice: price === 0 ? 'Free' : `₹${price.toLocaleString('en-IN')}`
  }));

  sortedUniquePrices = sortedUniquePrices?.filter(item => !isNaN(item?.price));

  // grouping all prices above the 6th unique price
  if (sortedUniquePrices.length > 6) {
    const sixthPrice = sortedUniquePrices[4].price as number;
    const seventhPrice = sortedUniquePrices[5].price as number;
    sortedUniquePrices = sortedUniquePrices.slice(0, 5);
    sortedUniquePrices.push({
      price: seventhPrice,
      order: 5,
      primaryColor: colorMappings[5].primaryColor,
      secondaryColor: colorMappings[5].secondaryColor,
      displayPrice: `₹${seventhPrice.toLocaleString('en-IN')}+`
    });
    // updating the priceColorArray to use the 6th color for all prices above the 6th
    sortedUniquePrices.forEach(item => {
      if (item.price > sixthPrice) {
        item.primaryColor = colorMappings[5].primaryColor;
        item.secondaryColor = colorMappings[5].secondaryColor;
      }
    });
  }

  return sortedUniquePrices;
}

export const getLowestPrice = (items: (Meal | Baggage)[]): number | null => {
  if (!Array.isArray(items) || items.length === 0) {
    return null; // return null if the input is not a valid array or is empty
  }

  let lowestPrice = items[0].price;

  for (let i = 1; i < items.length; i++) {
    if (items[i].price < lowestPrice) {
      lowestPrice = items[i].price;
    }
  }

  return lowestPrice;
};
