import { Box, Typography } from "@mui/material"
import { OAImage } from "../OAImage";

const OAZolveExclusivePlug = () => {
	return (
		<Box display='flex' alignItems='center'>
			<Box display='flex' flexDirection='column'>
        <Box display='flex'>
          <OAImage src='pop-celebrate.svg' folder="icons" alt='pop' />
          <Typography variant="h2" ml='2px' color='#3AA76D'>Get unbeatable prices</Typography>
          </Box>
          <Box>
          <Typography variant="body3" sx={{lineHeight: '16px'}} color='text.secondary'>Exclusive flight prices you won’t find anywhere else. Book with confidence!</Typography>
        </Box>
			</Box>
			<Box>
				<OAImage src='aircraft-exclusive.svg' folder='zolveIcons' sx={{maxWidth: '90px !important'}} alt='acft' />
			</Box>
		</Box>
  )
}

export default OAZolveExclusivePlug;