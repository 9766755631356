export const getSeatsCount = (state: any) => {
  return state?.userSelectedSegmentAncillaries?.reduce((segmentTotal: number, segment: any) => {
    return segmentTotal + (segment.seatDetails?.length || 0);
  }, 0) || 0;
};

export const getMealsCount = (state: any) => {
  return state?.userSelectedSegmentAncillaries?.reduce((segmentTotal: number, segment: any) => {
    return segmentTotal + (segment.mealDetails?.length || 0);
  }, 0) || 0;
};

export const getBaggagesCount = (state: any) => {
  return state?.userSelectedBaggageAncillaries?.reduce((baggageTotal: number, baggage: any) => {
    return baggageTotal + (baggage.baggageDetails?.length || 0);
  }, 0) || 0;
};