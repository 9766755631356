import { Box, Container, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import {
  formatNumberInINR,
  formatToINR,
  getBurnCoin,
  getDiscountPrice,
  getEarnCoin,
  getRsFromCoins,
} from '../utils';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../store/slices/userInfoSlice';
import { getUserInputState } from '../store/slices/userInputSlice';
import { getFeatureFlag } from '../store/slices/featuresSlice';
import OAFlightRoute from './OAFlightRoute';
import { OACoinBanner } from './OACoinBanner';
import { IFlight } from '../type';
import {
  getTotalBaggageCount,
  getTotalMealCount,
  getTotalSeatCount,
  getTotalSelectedBaggagePrice,
  getTotalSelectedMealPrice,
  getTotalSelectedSeatPrice,
  getTotalSelectedSmbPrice,
} from '../store/slices/flightOrderSlice';
import { useParams } from 'react-router-dom';
import { getBaggagesCount, getMealsCount, getSeatsCount } from '../pages/MyBooking/BookingStatus/helpers/smbCount';

// Define the interface for the props of FareListItem
interface FareListItemProps {
  primary: string;
  secondary: string;
  isDiscount?: boolean;
  isTotal?: boolean;
  secondary1?: any;
  fw?: string;
  orgCode?: string;
}

// Create the FareListItem component with proper types
const FareListItem = ({
  primary,
  secondary,
  isDiscount,
  isTotal,
  secondary1,
  fw,
  orgCode,
}: FareListItemProps) => {
  const variant = orgCode === 'ZOLVE' ? 'body3' : isTotal ? 'body1' : 'body2';
  let sx =
    orgCode === 'ZOLVE'
      ? { fontWeight: 600 }
      : isTotal
      ? { fontWeight: 600 }
      : isDiscount
      ? { color: 'success.main' }
      : {};

  return (
    <ListItem sx={{ padding: 0, mb: orgCode === 'ZOLVE' ? '16px' : '12px' }}>
      <ListItemText
        sx={{ m: 0 }}
        primary={
          <Typography variant={variant} sx={sx} fontWeight={fw}>
            {primary}
          </Typography>
        }
      />
      <Typography
        variant={orgCode === 'ZOLVE' ? 'body3' : isTotal ? 'body1' : 'body2'}
        sx={{ ...sx, fontWeight: 500 }}
        display="flex"
        flexDirection="column"
        alignItems="end"
      >
        <Box display="flex" alignItems={orgCode === 'ZOLVE' ? 'start' : 'center'}>
          <Typography
            variant={orgCode === 'ZOLVE' ? 'body3' : 'body2'}
            component="span"
            sx={{ mr: '6px', textDecoration: 'line-through', color: 'text.secondary' }}
          >
            {secondary1}
          </Typography>
          <Typography
            variant={isTotal ? 'body1' : orgCode === 'ZOLVE' ? 'body3' : 'body2'}
            fontWeight={isTotal ? 600 : orgCode === 'ZOLVE' ? 600 : 400}
          >
            {secondary}
          </Typography>
        </Box>
        {orgCode === 'ZOLVE' && (
          <Box>
            {primary.includes('Convenience fee') && (
              <Typography variant="body3" color="#3AA76D" fontWeight={600}>
                Zolve Discount
              </Typography>
            )}
          </Box>
        )}
      </Typography>
    </ListItem>
  );
};

export const OAFareSummary = ({
  onClick, 
  data, 
  selectedSmbData
}: { 
  onClick?: () => void; 
  data?: any, 
  selectedSmbData?: any
}) => {
  const params = useParams();
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const { isCoinsApplied } = useSelector(getUserInputState);
  const totalSeatCount = useSelector(getTotalSeatCount);
  const totalMealCount = useSelector(getTotalMealCount);
  const totalBaggageCount = useSelector(getTotalBaggageCount);

  const totalSeats = data?.selectedJourneyAncillaries?.[0] ? getSeatsCount(data?.selectedJourneyAncillaries?.[0]) : totalSeatCount;
  const totalMeals = data?.selectedJourneyAncillaries?.[0] ? getMealsCount(data?.selectedJourneyAncillaries?.[0]) : totalMealCount;
  const totalBaggage = data?.selectedJourneyAncillaries?.[0] ? getBaggagesCount(data?.selectedJourneyAncillaries?.[0]) : totalBaggageCount;

  const totalSelectedSmbPrice = useSelector(getTotalSelectedSmbPrice);
  const totalAmount =
    data?.orderAmount?.totalAmount -
    (data?.orderAmount?.seatCharges || 0) -
    (data?.orderAmount?.mealCharges || 0) -
    (data?.orderAmount?.baggageCharges || 0) +
    (totalSelectedSmbPrice || 0);
  const discountPrice = getDiscountPrice(totalAmount, coins);
  const burnCoin = getBurnCoin(data?.orderAmount?.totalAmount ?? 0, coins);
  const coinsToBeEarned = getEarnCoin(data?.orderAmount?.totalAmount);
  const burnCoinRs =
    data?.orderAmount?.paymentSummary?.amountInCoins ??
    (isCoinsApplied ? getRsFromCoins(burnCoin) : 0);

  const travellerCount = data?.travellerCount;
  const totalTravellers = travellerCount?.adult + travellerCount?.child + travellerCount?.infant;
  const departure = data?.flightSearchInfo?.from?.city;
  const arrival = data?.flightSearchInfo?.to?.city;
  const flightBasedBreakup = data?.orderAmount?.flightBasedBreakUp;
  const amountpayable = data?.orderAmount?.paymentSummary?.amountInCash;
  const seatPrice = useSelector(getTotalSelectedSeatPrice);
  const mealPrice = useSelector(getTotalSelectedMealPrice);
  const baggagePrice = useSelector(getTotalSelectedBaggagePrice);
  const totalSeatCharges = params?.orderId ? data?.orderAmount?.seatCharges : seatPrice;
  const totalMealCharges = params?.orderId ? data?.orderAmount?.mealCharges : mealPrice;
  const totalBaggageCharges = params?.orderId ? data?.orderAmount?.baggageCharges : baggagePrice;
  const flightIdToDepartureType = data?.flights?.reduce((acc: any, flight: IFlight) => {
    acc[flight?.id] = flight?.departureType;
    return acc;
  }, {});

  // sorting the flightBasedBreakup array by onward and return
  const sortedFlightBasedBreakup =
    flightBasedBreakup && flightBasedBreakup.length > 0
      ? [...flightBasedBreakup].sort((a: any, b: any) => {
          const departureTypeA = flightIdToDepartureType?.[a?.flightId];
          const departureTypeB = flightIdToDepartureType?.[b?.flightId];

          if (departureTypeA === 'ONWARD' && departureTypeB === 'RETURN') {
            return -1;
          } else if (departureTypeA === 'RETURN' && departureTypeB === 'ONWARD') {
            return 1;
          } else {
            return 0;
          }
        })
      : [];

  //when flightBasedBreakup is empty, using this
  const taxAndFees = (data?.orderAmount?.totalTaxAndFee ?? 0) + (data?.orderAmount?.markup ?? 0);
  const orgCode = data?.userOrganization?.organizationCode;

  return (
    <Box sx={{ mb: '24px' }}>
      <Box
        py={orgCode === 'ZOLVE' ? '10px' : 'revert'}
        mx={orgCode === 'ZOLVE' ? '20px' : 'revert'}
        border={orgCode === 'ZOLVE' ? '1px solid #EEEEEE' : 'revert'}
        borderRadius="12px"
      >
        {sortedFlightBasedBreakup?.map((flights: any, index: number) => {
          const taxesAndFees = (flights?.totalTaxAndFee ?? 0) + (flights?.markup ?? 0);
          return (
            <>
              {sortedFlightBasedBreakup?.length > 1 && (
                <Box
                  sx={
                    orgCode === 'ZOLVE'
                      ? {
                          bgcolor: '#FFFFFF',
                          p: '10px 16px',
                          mb: '15px',
                          borderBottom: '2px solid #F6F6F6',
                        }
                      : {
                          p: '10px 16px',
                          mb: '15px',
                        }
                  }
                >
                  <OAFlightRoute
                    from={index === 0 ? departure : arrival}
                    to={index === 0 ? arrival : departure}
                    icon={index === 0 ? 'flight-take-off.svg' : 'flight-land.svg'}
                    orgCode={orgCode}
                  />
                </Box>
              )}
              <List sx={{ py: '0px', px: '16px' }}>
                <FareListItem
                  primary={`Base fare (${totalTravellers} ${
                    totalTravellers > 1 ? 'travellers' : 'traveller'
                  })`}
                  secondary={`₹${formatNumberInINR(flights?.baseAmount)}`}
                  orgCode={orgCode}
                />
                <FareListItem
                  primary="Taxes and fees"
                  secondary={formatToINR(taxesAndFees)}
                  orgCode={orgCode}
                />
              </List>
            </>
          );
        })}

        {sortedFlightBasedBreakup?.length === 0 && (
          <>
            <List sx={{ py: '0px', px: '16px' }}>
              <FareListItem
                primary={`Base fare (${totalTravellers} ${
                  totalTravellers > 1 ? 'travellers' : 'traveller'
                })`}
                secondary={`₹${formatNumberInINR(data?.orderAmount?.baseAmount)}`}
                orgCode={orgCode}
              />
              <FareListItem
                primary="Taxes and fees"
                secondary={formatToINR(taxAndFees)}
                orgCode={orgCode}
              />
            </List>
          </>
        )}
        <Container sx={{ px: '16px' }}>
          {(totalSeatCharges > 0 || totalMealCharges > 0 || totalBaggageCharges > 0) && (
            <Divider sx={{ mb: '12px' }} />
          )}
          {totalSeatCharges > 0 && (
            <FareListItem primary="Seat charges" secondary={formatToINR(totalSeatCharges)} />
          )}
          {totalMealCharges > 0 && (
            <FareListItem primary="Meal charges" secondary={formatToINR(totalMealCharges)} />
          )}
          {totalBaggageCharges > 0 && (
            <FareListItem primary="Baggage charges" secondary={formatToINR(totalBaggageCharges)} />
          )}
        </Container>
        {data?.tripType === 'ROUND_TRIP' && (
          <Container sx={{ px: '16px' }}>
            <Divider
              sx={{ mb: '12px', ...(orgCode === 'ZOLVE' ? { borderStyle: 'dashed' } : {}) }}
            />
            <FareListItem
              fw="500"
              primary={'Total airfare'}
              secondary={`₹${
                params?.orderId
                  ? formatNumberInINR(data?.orderAmount?.supplierAmount)
                  : formatNumberInINR(
                      data?.orderAmount?.supplierAmount -
                        (data?.orderAmount?.seatCharges || 0) -
                        (data?.orderAmount?.mealCharges || 0) -
                        (data?.orderAmount?.baggageCharges || 0) +
                        (totalSelectedSmbPrice || 0)
                    )
              }`}
            />
          </Container>
        )}
        <Container sx={{ px: '16px' }}>
          {orgCode !== 'ZOLVE' && <Divider sx={{ mb: '12px' }} />}
          <FareListItem
            primary="Convenience fee (incl. GST)"
            secondary={formatToINR(data?.orderAmount?.convenienceFee ?? 0)}
            secondary1={
              data?.orderAmount?.strikeOfConvenienceFee
                ? formatToINR(data?.orderAmount?.strikeOfConvenienceFee)
                : null
            }
            orgCode={orgCode}
          />
          {orgCode === 'ZOLVE' && (
            <Divider
              sx={{
                mb: '16px',
                border: 'none',
                borderTop: '1px dashed #E2E2E2',
                height: '1px',
              }}
            />
          )}
          <FareListItem
            primary="Total amount payable"
            secondary={
              burnCoinsFeat && isCoinsApplied
                ? formatToINR(amountpayable ?? discountPrice)
                : formatToINR(amountpayable ?? totalAmount)
            }
            secondary1={burnCoinRs > 0 ? formatToINR(totalAmount) : null}
            isTotal
            orgCode={orgCode}
          />
          {burnCoinRs > 0 && (
            <OACoinBanner
              title="Money saved on this trip"
              subTitle={burnCoinRs}
              isCurrency={true}
              color="primary"
              sx={{ mb: '10px' }}
            />
          )}
          {coinsToBeEarned > 0 && (
            <OACoinBanner
              title={
                data?.bookingStatus === 'BOOKING_CONFIRMED' &&
                data?.orderCurrentStatus === 'COMPLETED'
                  ? 'Niyo coins earned on this trip'
                  : 'Niyo coins to be earned on this trip'
              }
              subTitle={data?.orderAmount?.paymentSummary?.earnedCoin ?? getEarnCoin(totalAmount)}
              isCurrency={false}
            />
          )}
        </Container>
      </Box>
    </Box>
  );
};
