import React, { FC, useMemo, useState, useEffect } from 'react';
import { useGetSMBQuery, useUpdateSMBMutation } from '../../../services/createOrderApi';
import { Container, Grid } from '@mui/material';
import { OASwipeableDrawer } from '../../../components';
import OASMB from '../../../components/OASMB';
import { useDispatch, useSelector } from '../../../store';
import {
  getBaggageCount,
  getFlightOrderState,
  getMealCount,
  getSeatCount,
  getTotalBaggageCount,
  getTotalMealCount,
  getTotalSeatCount,
  setIsReviewAddOns,
} from '../../../store/slices/flightOrderSlice';
import AddOnsReview from '../../../components/OASMB/components/AddOnsReview';
import { setMessage } from '../../../store/slices/snackbarSlice';
import CardItem from './CardItem';
import {
  getLowestPrice,
  getSortedUniquePrices,
} from '../../../components/OASMB/helpers/getAveragePrices';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { getCurrentTabString } from '../../../utils';
interface SMBProps {
  ancillaryId: string;
  totalTravellers: number;
  isDiscountApplied: boolean;
  totalSelectedFlightPrice: number;
  discountPrice: number;
  // isReviewAddOnDrawerOpen: boolean;
  // toggleReviewAddOnDrawer: () => void;
  // setIsReviewAddOnDrawerOpen: any;
  onClickHandler: () => void;
  selectedTravelers: any;
  totalSegments?: number;
  isReturn: boolean;
  infantCount: number;
  scrollToTravellers?: any;
}
const SMB: FC<SMBProps> = ({
  ancillaryId,
  totalTravellers,
  isDiscountApplied,
  totalSelectedFlightPrice,
  discountPrice,
  // isReviewAddOnDrawerOpen,
  // toggleReviewAddOnDrawer,
  // setIsReviewAddOnDrawerOpen,
  onClickHandler,
  selectedTravelers,
  totalSegments,
  isReturn,
  infantCount,
  scrollToTravellers,
}) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const { data, error } = useGetSMBQuery<any>(ancillaryId, {
    skip: !ancillaryId,
  });

  const { smbData: selectedSmbData, isReviewAddOns } = useSelector(getFlightOrderState);
  const [updateSMB] = useUpdateSMBMutation();
  const [isSmbDrawerOpen, setIsSmbDrawerOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<number>(1);
  const [displaySmb, setDisplaySmb] = useState<boolean>(true);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const toggleSmbDrawer = (currentTab?: number) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    if (typeof currentTab === 'number' && !isNaN(currentTab)) {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'Itinerary',
          ctaAction: getCurrentTabString(currentTab),
          screenDuration: totalScreenDuration?.toString(),
        },
        posthog
      );
    } else {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SMB',
          ctaAction: 'Close',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            tab: getCurrentTabString(tabValue),
          },
        },
        posthog
      );
    }

    if (!data) return;

    if (selectedTravelers !== totalTravellers) {
      scrollToTravellers?.scrollIntoView({ behavior: 'smooth' });
      dispatch(setMessage('Please select travellers'));
    } else {
      // setIsReviewAddOnDrawerOpen(false);
      if (currentTab) {
        setTabValue(currentTab);
      }
      setIsSmbDrawerOpen(!isSmbDrawerOpen);
    }
  };

  const segmentBasedTabs = useMemo(() => {
    if (tabValue === 3) {
      //for baggage
      return (
        data?.data?.[0]?.journeyAncillaries?.map((item: any) => item?.originDestinationInfo) || []
      );
    } else {
      return (
        data?.data?.[0]?.journeyAncillaries?.flatMap((data: any) =>
          data?.segmentAncillaries?.map((segment: any) => {
            return {
              from: segment?.originDestinationInfo?.from,
              to: segment?.originDestinationInfo?.to,
              airline: segment?.flight?.airlineCode,
              flight: segment?.flight,
              segmentsInfo: segment?.segmentsInfo,
            };
          })
        ) || []
      );
    }
  }, [data?.data, tabValue]);

  const [selectedSegment, setSelectedSegment] = useState<{
    from: string;
    to: string;
  }>({
    from: segmentBasedTabs?.[0]?.from || '',
    to: segmentBasedTabs?.[0]?.to || '',
  });

  useEffect(() => {
    if (segmentBasedTabs.length > 0) {
      setSelectedSegment({
        from: segmentBasedTabs[0].from,
        to: segmentBasedTabs[0].to,
      });
    }
  }, [segmentBasedTabs]);

  const seatRows =
    data?.data?.[0]?.journeyAncillaries?.[0]?.segmentAncillaries?.[0]?.seatAncillaries
      ?.aircraftLayout?.lowerDeck?.compartments?.[0]?.rows;
  const mealsArray =
    data?.data?.[0]?.journeyAncillaries?.[0]?.segmentAncillaries?.[0]?.mealAncillaries?.mealInfos;
  const baggageArray =
    data?.data?.[0]?.journeyAncillaries?.[0]?.baggageAncillary?.baggageDetails?.[0]?.baggageInfos;

  const isSeatSelected = getSeatCount(selectedSmbData, selectedSegment);
  const isMealSelected = getMealCount(selectedSmbData, selectedSegment);
  const isBaggageSelected = getBaggageCount(selectedSmbData, selectedSegment);

  useEffect(() => {
    const showReviewAddOns = isSeatSelected && isMealSelected && isBaggageSelected;
    dispatch(setIsReviewAddOns(!showReviewAddOns));
  }, [isSeatSelected, isMealSelected, isBaggageSelected]);

  const getSeatPriceArray = getSortedUniquePrices([seatRows]);
  const lowestMealPrice = getLowestPrice(mealsArray);
  const lowestBaggagePrice = getLowestPrice(baggageArray);
  
  const onClickUpdateHandler = () => {
    setIsSmbDrawerOpen(false);
    onClickHandler();
  };
  const seatCount = useSelector(getTotalSeatCount);
  const mealCount = useSelector(getTotalMealCount);
  const baggageCount = useSelector(getTotalBaggageCount);

  useEffect(() => {
    if (error && !error?.data?.additionalData) {
      setDisplaySmb(false);
    }
  }, [error]);
  
  return (
    <>
      {displaySmb && (
        <Container sx={{ 
          boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.06)', 
          bgcolor: '#FFF' 
          }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={4} onClick={() => toggleSmbDrawer(1)}>
              <CardItem
                title="Seats"
                subTitle={
                  getSeatPriceArray?.length < 1 ? '' : getSeatPriceArray?.[0]?.price === 0 ? 'Get for free' : `From ₹${getSeatPriceArray?.[0]?.price?.toFixed(2)}`
                }
                isSelected={isSeatSelected > 0}
                isLoading={!data}
                itemCount={seatCount}
                totalItemsToBeAdded={(totalTravellers - (infantCount ?? 0)) * (totalSegments ?? 1)}
              />
            </Grid>
            <Grid item xs={4} onClick={() => toggleSmbDrawer(2)}>
              <CardItem
                title="Meals"
                subTitle={!lowestMealPrice && lowestMealPrice !== 0 ? '' : lowestMealPrice === 0 ? 'Get for free' :`From ₹${lowestMealPrice?.toFixed(2)}`}
                isSelected={isMealSelected > 0}
                isLoading={!data}
                itemCount={mealCount}
                totalItemsToBeAdded={(totalTravellers - (infantCount ?? 0)) * (totalSegments ?? 1)}
              />
            </Grid>
            <Grid item xs={4} onClick={() => toggleSmbDrawer(3)}>
              <CardItem
                title="Baggage"
                subTitle={!lowestBaggagePrice && lowestBaggagePrice !== 0 ? '' : lowestBaggagePrice === 0 ? 'Get for free' : `From ₹${lowestBaggagePrice?.toFixed(2)}`}
                isSelected={baggageCount > 0}
                isLoading={!data}
                itemCount={baggageCount}
                totalItemsToBeAdded={(totalTravellers - (infantCount ?? 0)) * (isReturn ? 2 : 1)}
              />
            </Grid>
          </Grid>
        </Container>
      )}
      <OASwipeableDrawer
        title="Select add-ons"
        open={isSmbDrawerOpen}
        onOpen={toggleSmbDrawer}
        onClose={toggleSmbDrawer}
        maxHeight={96}
        height={96}
        isContainer={false}
      >
        <OASMB
          selectedTab="Seat"
          smbData={data?.data?.[0]?.journeyAncillaries}
          tabValue={tabValue}
          setTabValue={setTabValue}
          totalTravellers={totalTravellers - (infantCount ?? 0)}
          updateSmbAction={updateSMB}
          isDiscountApplied={isDiscountApplied}
          totalSelectedFlightPrice={totalSelectedFlightPrice}
          discountPrice={discountPrice}
          selectedSmbData={selectedSmbData}
          segmentBasedTabs={segmentBasedTabs}
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
          onClickHandler={onClickUpdateHandler}
        />
      </OASwipeableDrawer>
      {/* <OASwipeableDrawer
        title="Review add-ons"
        open={isReviewAddOnDrawerOpen}
        onClose={toggleReviewAddOnDrawer}
        onOpen={toggleReviewAddOnDrawer}
        isContainer={false}
        maxHeight={96}
      >
        <AddOnsReview
          segmentBasedTabs={segmentBasedTabs}
          totalTravellers={totalTravellers - (infantCount ?? 0)}
          toggleSmbDrawer={toggleSmbDrawer}
          onClickHandler={onClickHandler}
        />
      </OASwipeableDrawer> */}
    </>
  );
};

export default SMB;
