import { FC, useEffect, useState } from 'react';
import { Box, Card, Container, Typography } from '@mui/material';

import OATripChip from '../../../OATripChip';
import AncillaryRow from './AncillaryRow';
import { OAChip } from '../../../basic/OAChip';
import { useSelector } from '../../../../store';
import { getFlightOrderState } from '../../../../store/slices/flightOrderSlice';

interface AddOnsSummaryProps {
  order?: any;
}
const AddOnsSummary: FC<AddOnsSummaryProps> = ({ order }) => {
  const { smbData: selectedSmbData } = useSelector(getFlightOrderState);
  const [selectedSegment, setSelectedSegment] = useState<any>(null);
  const [sortedSegments, setSortedSegments] = useState<any>(null);
  const [seat, setSeat] = useState<any>('');
  const [meal, setMeal] = useState<any>('');
  const [baggage, setBaggage] = useState<any>('');

  useEffect(() => {
    if (order || selectedSmbData) {
      const currentData = order || normalizeData(selectedSmbData?.userSelectedJourneyAncillaries);
      if (currentData) {
        const allSegments = flattenSegments(currentData);
        const sortedSegments = allSegments.sort((a: any, b: any) => a.segmentId - b.segmentId);
        setSortedSegments(sortedSegments);
        onClickSelectSegment(sortedSegments?.[0]);
      }
    }
  }, [order, selectedSmbData]);

  const normalizeData = (data: any) => {
    let segmentCounter = 1;
    const normalizeReturn = data?.map((entry: any) => ({
      ...entry,
      segments: entry?.userSelectedSegmentAncillaries?.map((sa: any) => ({
        ...sa.segmentOriginDestinationInfo,
        segmentId: segmentCounter++,
        bookingInfos: [
          {
            selectedAncillaries: [
              ...mapAncillaryDetails(sa, 'SEAT', (s: any) => `${s.rowId}${s.columnId}`),
              ...mapAncillaryDetails(sa, 'MEAL', (m: any) => m.mealInfo),
              ...mapBaggageDetails(entry?.userSelectedBaggageAncillaries),
            ],
          },
        ],
        departure: { airport: { iata: sa.segmentOriginDestinationInfo.from } },
        arrival: { airport: { iata: sa.segmentOriginDestinationInfo.to } },
      })),
    }));
    return { flights: normalizeReturn };
  };

  const flattenSegments = (data: any) => {
    return data?.flights?.flatMap((flight: any) => flight.segments) || [];
  };

  const mapAncillaryDetails = (segment: any, type: any, mapFunc: any) => {
    return segment[type?.toLowerCase() + 'Details']
      ?.map(mapFunc)
      ?.filter((value: any) => value)
      ?.map((value: any) => ({ type, value }));
  };

  const mapBaggageDetails = (entry: any) => {
    const outputData = entry?.flatMap((item: any) =>
      item?.baggageDetails?.map((baggage: any) => ({
        type: 'BAGGAGE',
        value: baggage.displayDetail,
      }))
    );
    return outputData;
  };

  const getAncillaryData = (bookingInfos: any, ancillaryType: any) =>
    bookingInfos?.flatMap((traveller: any) =>
      traveller?.selectedAncillaries
        ?.filter((ancillary: any) => ancillary?.type === ancillaryType && ancillary?.value)
        ?.map((ancillary: any) => ancillary?.value)
    );

    const getMealCounts = (meals: any[]) => {
      const mealCounts = new Map();
      meals?.forEach((meal) => {
        if (meal !== undefined) {
          mealCounts?.set(meal, (mealCounts?.get(meal) || 0) + 1);
        }
      });
      return Array?.from(mealCounts)
        ?.map(([meal, count]) => `${count}x ${truncateMeal(meal)}`)
        ?.join(', ');
    };
    
    const truncateMeal = (meal: string) => {
      const words = meal?.split(' ');
      if (words?.length > 1) {
        return words[0]?.slice(0, 5) + '..';
      }
      return meal?.slice(0, 5) + '..';
    };

  const onClickSelectSegment = (segment: any) => {
    if (segment) {
      setSelectedSegment(segment);
      const bookingInfos = segment?.bookingInfos;
      const seatValues = getAncillaryData(bookingInfos, 'SEAT');
      setSeat(seatValues?.join(', '));
      setMeal(getMealCounts(getAncillaryData(bookingInfos, 'MEAL')));
      setBaggage(getMealCounts(getAncillaryData(bookingInfos, 'BAGGAGE')));
    }
  };
  return (
    <Container>
      <Typography fontWeight={600}>Add-ons</Typography>
      <Box
        mt="18px"
        display="flex"
        sx={{
          overflowX: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        }}
      >
        {sortedSegments?.map((segment: any) => (
          <OAChip
            key={segment?.segmentId}
            label={
              <OATripChip
                from={segment?.departure?.airport?.iata}
                to={segment?.arrival?.airport?.iata}
                airline={segment?.airline?.code}
              />
            }
            sx={{ p: '5px 1px', mr: '8px' }}
            selected={segment?.segmentId === selectedSegment?.segmentId}
            onClick={() => onClickSelectSegment(segment)}
          />
        ))}
      </Box>
      <Card sx={{ mt: sortedSegments?.length > 0 ? '18px' : '0px', p: '0 15px' }}>
        <AncillaryRow label="Seats" value={(!seat || seat === '' ? 'Not opted' : seat)} icon="seat-icon.svg" />
        <AncillaryRow label="Meals" value={(!meal || meal === '' ? 'Not opted' : meal)} icon="meals-icon.svg" />
        <AncillaryRow label="Baggage" value={(!baggage || baggage === '' ? 'Not opted' : baggage)} icon="baggage-icon.svg" />
      </Card>
    </Container>
  );
};

export default AddOnsSummary;