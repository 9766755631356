import { Box, Skeleton, Typography } from '@mui/material';
import RecentFlightSearchItem from './RecentFlightSearchItem';

interface RecentFlightSearchProps {
  data?: any;
  isLoading: boolean;
}

const layoutProps = {
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  overflowY: 'hidden',
  minWidth: '100%', // Adjust based on your layout
  scrollbarWidth: 'none' /* For Firefox */,
  '&::-webkit-scrollbar': {
    display: 'none' /* For Chrome, Safari and Edge */,
  },
  maxHeight: '70px',
  // Add more styling as needed to match your layout
};

const RecentFlightSearch = ({ data, isLoading }: RecentFlightSearchProps) => {
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton sx={{ fontSize: '1rem', width: '100px' }} />
          <Box sx={layoutProps}>
            {[...Array(3)]?.map((_, index) => (
              <Skeleton key={index} height="110px" width="160px" sx={{ marginRight: 2 }} />
            ))}
          </Box>
        </>
      ) : (
        data?.data?.recentSearches?.length > 0 && (
          <>
            <Typography variant="body1" sx={{ fontWeight: 600, mb: '20px', ml: '20px' }}>
              Recent Searches
            </Typography>
            <Box sx={layoutProps}>
              {data?.data?.recentSearches?.map((item: any, index: number) => (
                <Box key={index} sx={{ ml: index === 0 ? '20px' : '' }}>
                  <RecentFlightSearchItem key={item?.id} item={item} />
                </Box>
              ))}
            </Box>
          </>
        )
      )}
    </>
  );
};

export default RecentFlightSearch;
