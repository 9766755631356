import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface BaggageDetails {
  baggageCode: string;
  baggageInfo: string;
  paxIndex: string;
  price: number;
  displayDetail: string;
}

interface SeatDetails {
  columnId: string;
  rowId: string;
}

interface MealDetails {
  mealCode: string;
  mealInfo: string;
  price: number;
  mealType: any;
}

interface UserSelectedBaggageAncillaries {
  originDestinationInfo: {
    from: string;
    to: string;
  };
  baggageDetails: BaggageDetails[];
}

interface UserSelectedSegmentAncillaries {
  segmentOriginDestinationInfo: {
    from: string;
    to: string;
  };
  seatDetails: SeatDetails[];
  mealDetails: MealDetails[];
}

interface JourneyAncillary {
  journeyOriginDestinationInfo: {
    from: string;
    to: string;
  };
  isSegmentLevelBaggage: boolean;
  userSelectedBaggageAncillaries: UserSelectedBaggageAncillaries[];
  userSelectedSegmentAncillaries: UserSelectedSegmentAncillaries[];
}

export interface FlightOrderStateProps {
  flightData: any;
  loading: boolean;
  error: any;
  smbData: {
    userSelectedJourneyAncillaries: JourneyAncillary[];
  };
  isReviewAddOns: boolean;
}

interface SegmentAncillaries {
  segmentOriginDestinationInfo: { from: string; to: string };
  seatDetails: SeatDetails[];
  mealDetails: any[];
}

interface JourneyAncillaries {
  journeyOriginDestinationInfo: { from: string; to: string };
  isSegmentLevelBaggage: boolean;
  userSelectedBaggageAncillaries: any[];
  userSelectedSegmentAncillaries: SegmentAncillaries[];
}

const initialState: FlightOrderStateProps = {
  flightData: {},
  loading: false,
  error: '',
  smbData: {
    userSelectedJourneyAncillaries: [],
  },
  isReviewAddOns: true,
};

const findJourneyIndex = (journeys: JourneyAncillaries[], from: string, to: string) => {
  return journeys.findIndex(
    (journey) =>
      journey.journeyOriginDestinationInfo.from === from &&
      journey.journeyOriginDestinationInfo.to === to
  );
};

const findSegmentIndex = (segments: SegmentAncillaries[], from: string, to: string) => {
  return segments.findIndex(
    (segment) =>
      segment.segmentOriginDestinationInfo.from === from &&
      segment.segmentOriginDestinationInfo.to === to
  );
};

const initializeJourneyAncillaries = (state: FlightOrderStateProps, from: string, to: string) => {
  let journeyIndex = findJourneyIndex(state.smbData.userSelectedJourneyAncillaries, from, to);

  if (journeyIndex === -1) {
    state.smbData.userSelectedJourneyAncillaries.push({
      journeyOriginDestinationInfo: { from, to },
      isSegmentLevelBaggage: false,
      userSelectedBaggageAncillaries: [{
        originDestinationInfo: { from, to },
        baggageDetails: []
      }],
      userSelectedSegmentAncillaries: [],
    });
    journeyIndex = state.smbData.userSelectedJourneyAncillaries.length - 1;
  }

  return journeyIndex;
};

export const flightOrderSlice = createSlice({
  name: 'flightOrder',
  initialState,
  reducers: {
    setFlightData: (state, action) => {
      state.flightData = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSmbData: (state, action) => {
      state.smbData = action.payload;
    },
    setIsReviewAddOns: (state, action) => {
      state.isReviewAddOns = action.payload;
    },
    addSeatSelection: (
      state,
      action: PayloadAction<{
        from: string;
        to: string;
        seatDetails: SeatDetails;
        totalTravellers: number;
        apiResponse: any;
      }>
    ) => {
      const { from, to, seatDetails, totalTravellers, apiResponse } = action.payload;

      apiResponse?.forEach((journeyResponse: any) => {
        const mainJourneyInfo = journeyResponse.originDestinationInfo;

        let journeyIndex = findJourneyIndex(
          state.smbData.userSelectedJourneyAncillaries,
          mainJourneyInfo.from,
          mainJourneyInfo.to
        );

        if (journeyIndex === -1) {
          state.smbData.userSelectedJourneyAncillaries.push({
            journeyOriginDestinationInfo: { from: mainJourneyInfo.from, to: mainJourneyInfo.to },
            isSegmentLevelBaggage: false,
            userSelectedBaggageAncillaries: [{
              originDestinationInfo: { from: mainJourneyInfo.from, to: mainJourneyInfo.to },
              baggageDetails: []
            }],
            userSelectedSegmentAncillaries: [],
          });
          journeyIndex = state.smbData.userSelectedJourneyAncillaries.length - 1;
        }

        const stateJourney = state.smbData.userSelectedJourneyAncillaries[journeyIndex];

        journeyResponse.segmentAncillaries.forEach((segment: any) => {
          let segmentIndex = findSegmentIndex(
            stateJourney.userSelectedSegmentAncillaries,
            segment.originDestinationInfo.from,
            segment.originDestinationInfo.to
          );

          if (segmentIndex === -1) {
            stateJourney.userSelectedSegmentAncillaries.push({
              segmentOriginDestinationInfo: segment.originDestinationInfo,
              seatDetails: [],
              mealDetails: [],
            });
            segmentIndex = stateJourney.userSelectedSegmentAncillaries.length - 1;
          }

          const stateSegment = stateJourney.userSelectedSegmentAncillaries[segmentIndex];

          if (
            segment.originDestinationInfo.from === from &&
            segment.originDestinationInfo.to === to
          ) {
            const selectedSeatIndex = stateSegment.seatDetails.findIndex(
              (seat) => seat.columnId === seatDetails.columnId && seat.rowId === seatDetails.rowId
            );

            if (selectedSeatIndex >= 0) {
              stateSegment.seatDetails.splice(selectedSeatIndex, 1);
            } else {
              if (totalTravellers === 1) {
                stateSegment.seatDetails = [seatDetails];
              } else {
                if (stateSegment.seatDetails.length >= totalTravellers) {
                  stateSegment.seatDetails.shift();
                }
                stateSegment.seatDetails.push(seatDetails);
              }
            }
          }
        });
      });
    },

    addMealSelection: (
      state,
      action: PayloadAction<{
        from: string;
        to: string;
        mealDetails: MealDetails;
        totalTravellers: number;
        apiResponse: any;
      }>
    ) => {
      const { from, to, mealDetails, totalTravellers, apiResponse } = action.payload;

      apiResponse?.forEach((journeyResponse: any) => {
        const mainJourneyInfo = journeyResponse.originDestinationInfo;

        let journeyIndex = findJourneyIndex(
          state.smbData.userSelectedJourneyAncillaries,
          mainJourneyInfo.from,
          mainJourneyInfo.to
        );

        if (journeyIndex === -1) {
          state.smbData.userSelectedJourneyAncillaries.push({
            journeyOriginDestinationInfo: { from: mainJourneyInfo.from, to: mainJourneyInfo.to },
            isSegmentLevelBaggage: false,
            userSelectedBaggageAncillaries: [{
              originDestinationInfo: { from: mainJourneyInfo.from, to: mainJourneyInfo.to },
              baggageDetails: []
            }],
            userSelectedSegmentAncillaries: [],
          });
          journeyIndex = state.smbData.userSelectedJourneyAncillaries.length - 1;
        }

        const stateJourney = state.smbData.userSelectedJourneyAncillaries[journeyIndex];

        journeyResponse.segmentAncillaries.forEach((segment: any) => {
          let segmentIndex = findSegmentIndex(
            stateJourney.userSelectedSegmentAncillaries,
            segment.originDestinationInfo.from,
            segment.originDestinationInfo.to
          );

          if (segmentIndex === -1) {
            stateJourney.userSelectedSegmentAncillaries.push({
              segmentOriginDestinationInfo: segment.originDestinationInfo,
              seatDetails: [],
              mealDetails: [],
            });
            segmentIndex = stateJourney.userSelectedSegmentAncillaries.length - 1;
          }

          const stateSegment = stateJourney.userSelectedSegmentAncillaries[segmentIndex];

          if (
            segment.originDestinationInfo.from === from &&
            segment.originDestinationInfo.to === to
          ) {
            const selectedMealCount = stateSegment.mealDetails.filter(
              (meal) => meal.mealCode === mealDetails.mealCode
            ).length;
    
            if (stateSegment.mealDetails.length < totalTravellers) {
              stateSegment.mealDetails.push(mealDetails);
            } else if (selectedMealCount < totalTravellers) {
              // If there are already meals selected, remove one from the beginning
              stateSegment.mealDetails.shift();
              stateSegment.mealDetails.push(mealDetails);
            }
          }
        });
      });
    },

    removeMealSelection: (
      state,
      action: PayloadAction<{
        from: string;
        to: string;
        mealCode: string;
        totalTravellers: number;
      }>
    ) => {
      const { from, to, mealCode } = action.payload;

      state.smbData.userSelectedJourneyAncillaries.forEach((journey) => {
        journey.userSelectedSegmentAncillaries.forEach((segment) => {
          if (
            segment.segmentOriginDestinationInfo.from === from &&
            segment.segmentOriginDestinationInfo.to === to
          ) {
            // Remove only the specific meal with the given mealCode
            const selectedMealIndex = segment.mealDetails.findIndex(
              (meal) => meal.mealCode === mealCode
            );
            if (selectedMealIndex >= 0) {
              segment.mealDetails.splice(selectedMealIndex, 1);
            }
          }
        });
      });
    },    

    addBaggageSelection: (
      state,
      action: PayloadAction<{
        from: string;
        to: string;
        baggageDetails: BaggageDetails;
        totalTravellers: number;
        apiResponse: any;
      }>
    ) => {
      const { from, to, baggageDetails, totalTravellers, apiResponse } = action.payload;
      const journeyResponse = apiResponse.find(
        (journey: any) =>
          journey.originDestinationInfo.from === from && journey.originDestinationInfo.to === to
      );

      if (!journeyResponse) return;

      const mainJourneyInfo = journeyResponse.originDestinationInfo;

      let journeyIndex = findJourneyIndex(
        state.smbData.userSelectedJourneyAncillaries,
        mainJourneyInfo.from,
        mainJourneyInfo.to
      );

      if (journeyIndex === -1) {
        state.smbData.userSelectedJourneyAncillaries.push({
          journeyOriginDestinationInfo: { from: mainJourneyInfo.from, to: mainJourneyInfo.to },
          isSegmentLevelBaggage: false,
          userSelectedBaggageAncillaries: [{
            originDestinationInfo: { from: mainJourneyInfo.from, to: mainJourneyInfo.to },
            baggageDetails: []
          }],
          userSelectedSegmentAncillaries: [],
        });
        journeyIndex = state.smbData.userSelectedJourneyAncillaries.length - 1;
      }

      const stateJourney = state.smbData.userSelectedJourneyAncillaries[journeyIndex];

       // ensuring userSelectedSegmentAncillaries is created with empty values
       if (stateJourney.userSelectedSegmentAncillaries.length === 0) {
        journeyResponse.segmentAncillaries.forEach((segment: any) => {
          stateJourney.userSelectedSegmentAncillaries.push({
            segmentOriginDestinationInfo: segment.originDestinationInfo,
            seatDetails: [],
            mealDetails: [],
          });
        });
      }

      if (totalTravellers === 1) {
        stateJourney.userSelectedBaggageAncillaries.forEach((baggage) => {
          if (
            baggage.originDestinationInfo.from === from &&
            baggage.originDestinationInfo.to === to
          ) {
            baggage.baggageDetails = [];
          }
        });
      }

      const selectedBaggageIndex = stateJourney.userSelectedBaggageAncillaries.findIndex(
        (baggage: any) =>
          baggage.originDestinationInfo.from === from && baggage.originDestinationInfo.to === to
      );

      if (selectedBaggageIndex >= 0) {
        if (
          stateJourney.userSelectedBaggageAncillaries[selectedBaggageIndex].baggageDetails.length <
          totalTravellers
        ) {
          stateJourney.userSelectedBaggageAncillaries[selectedBaggageIndex].baggageDetails.push(
            baggageDetails
          );
        }
      } else {
        stateJourney.userSelectedBaggageAncillaries.push({
          originDestinationInfo: { from, to },
          baggageDetails: [baggageDetails],
        });
      }
    },

    removeBaggageSelection: (
      state,
      action: PayloadAction<{
        from: string;
        to: string;
        baggageCode: string;
        totalTravellers: number;
      }>
    ) => {
      const { from, to, baggageCode } = action.payload;

      state.smbData.userSelectedJourneyAncillaries.forEach((journey) => {
        journey.userSelectedBaggageAncillaries.forEach((baggage) => {
          if (
            baggage.originDestinationInfo.from === from &&
            baggage.originDestinationInfo.to === to
          ) {
            const baggageDetailsIndex = baggage.baggageDetails.findIndex(
              (baggageDetail) => baggageDetail.baggageCode === baggageCode
            );

            if (baggageDetailsIndex >= 0) {
              baggage.baggageDetails.splice(baggageDetailsIndex, 1);
            }

            // Ensure the structure is maintained even if baggageDetails is empty
            if (baggage.baggageDetails.length === 0) {
              baggage.baggageDetails = [];
            }
          }
        });
      });
    },
  },
});

export const getFlightOrderState = (state: any) => state.flightData;
export const getSmbData = (state: any) => state.flightData.smbData;

export const {
  setFlightData,
  setError,
  setSmbData,
  addSeatSelection,
  addMealSelection,
  removeMealSelection,
  addBaggageSelection,
  removeBaggageSelection,
  setIsReviewAddOns
} = flightOrderSlice.actions;
export default flightOrderSlice.reducer;

export const getSeatCount = (state: any, segment: { from: string; to: string }) => {
  return state?.userSelectedJourneyAncillaries?.reduce((count: number, journey: any) => {
    return journey?.userSelectedSegmentAncillaries?.reduce((segmentCount: number, seg: any) => {
      if (
        seg.segmentOriginDestinationInfo.from === segment.from &&
        seg.segmentOriginDestinationInfo.to === segment.to
      ) {
        return segmentCount + (seg.seatDetails?.length || 0);
      }
      return segmentCount;
    }, count);
  }, 0);
};

export const getMealCount = (state: any, segment: { from: string; to: string }) => {
  return state?.userSelectedJourneyAncillaries?.reduce((count: number, journey: any) => {
    return journey?.userSelectedSegmentAncillaries?.reduce((segmentCount: number, seg: any) => {
      if (
        seg.segmentOriginDestinationInfo.from === segment.from &&
        seg.segmentOriginDestinationInfo.to === segment.to
      ) {
        return segmentCount + (seg?.mealDetails?.length || 0);
      }
      return segmentCount;
    }, count);
  }, 0);
};

export const getBaggageCount = (state: any, segment: { from: string; to: string }) => {
  return state?.userSelectedJourneyAncillaries?.reduce((count: number, journey: any) => {
    if (
      journey.journeyOriginDestinationInfo.from === segment.from &&
      journey.journeyOriginDestinationInfo.to === segment.to
    ) {
      return journey.userSelectedBaggageAncillaries.reduce((baggageCount: number, baggage: any) => {
        if (
          baggage.originDestinationInfo.from === segment.from &&
          baggage.originDestinationInfo.to === segment.to
        ) {
          return baggageCount + baggage.baggageDetails.length;
        }
        return baggageCount;
      }, count);
    }
    return count;
  }, 0);
};

export const getTotalSelectedSmbPrice = (state: any) => {
  return state?.flightData?.smbData?.userSelectedJourneyAncillaries?.reduce(
    (totalPrice: any, journey: any) => {
      const segmentPrice = journey?.userSelectedSegmentAncillaries?.reduce(
        (segmentTotal: any, segment: any) => {
          const seatPrice = segment?.seatDetails?.reduce(
            (seatTotal: any, seat: any) => seatTotal + seat.price,
            0
          );
          const mealPrice = segment?.mealDetails?.reduce(
            (mealTotal: any, meal: any) => mealTotal + meal.price,
            0
          );
          return segmentTotal + seatPrice + mealPrice;
        },
        0
      );

      const baggagePrice = journey?.userSelectedBaggageAncillaries?.reduce(
        (baggageTotal: any, baggage: any) => {
          return (
            baggageTotal +
            baggage?.baggageDetails?.reduce(
              (baggageDetailTotal: any, baggageDetail: any) =>
                baggageDetailTotal + baggageDetail.price,
              0
            )
          );
        },
        0
      );

      return totalPrice + segmentPrice + baggagePrice;
    },
    0
  );
};

export const getTotalSelectedSeatPrice = (state: FlightOrderStateProps) => {
  return state?.flightData?.smbData?.userSelectedJourneyAncillaries?.reduce(
    (totalPrice: number, journey: any) => {
      return journey?.userSelectedSegmentAncillaries?.reduce((segmentTotal: any, segment: any) => {
        return (
          segmentTotal +
          segment?.seatDetails?.reduce((seatTotal: any, seat: any) => seatTotal + seat.price, 0)
        );
      }, totalPrice);
    },
    0
  );
};

export const getTotalSelectedMealPrice = (state: FlightOrderStateProps) => {
  return state?.flightData?.smbData?.userSelectedJourneyAncillaries?.reduce(
    (totalPrice: number, journey: any) => {
      return journey?.userSelectedSegmentAncillaries?.reduce((segmentTotal: any, segment: any) => {
        return (
          segmentTotal +
          segment?.mealDetails?.reduce((mealTotal: any, meal: any) => mealTotal + meal.price, 0)
        );
      }, totalPrice);
    },
    0
  );
};

export const getTotalSelectedBaggagePrice = (state: FlightOrderStateProps) => {
  return state?.flightData?.smbData?.userSelectedJourneyAncillaries?.reduce(
    (totalPrice: number, journey: any) => {
      return journey?.userSelectedBaggageAncillaries?.reduce((baggageTotal: any, baggage: any) => {
        return (
          baggageTotal +
          baggage?.baggageDetails?.reduce(
            (baggageDetailTotal: any, baggageDetail: any) =>
              baggageDetailTotal + baggageDetail.price,
            0
          )
        );
      }, totalPrice);
    },
    0
  );
};

export const getTotalSeatCount = (state: any) => {
  return state?.flightData?.smbData?.userSelectedJourneyAncillaries?.reduce((totalCount: number, journey: any) => {
    return journey?.userSelectedSegmentAncillaries?.reduce((segmentTotal: number, segment: any) => {
      return segmentTotal + (segment.seatDetails?.length || 0);
    }, totalCount);
  }, 0);
};

export const getTotalMealCount = (state: any) => {
  return state?.flightData?.smbData?.userSelectedJourneyAncillaries?.reduce((totalCount: number, journey: any) => {
    return journey?.userSelectedSegmentAncillaries?.reduce((segmentTotal: number, segment: any) => {
      return segmentTotal + (segment?.mealDetails?.length || 0);
    }, totalCount);
  }, 0);
};

export const getTotalBaggageCount = (state: any) => {
  return state?.flightData?.smbData?.userSelectedJourneyAncillaries?.reduce((totalCount: number, journey: any) => {
    return journey.userSelectedBaggageAncillaries.reduce((baggageTotal: number, baggage: any) => {
      return baggageTotal + baggage.baggageDetails.length;
    }, totalCount);
  }, 0);
};
