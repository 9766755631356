import { Typography } from "@mui/material"

const OAExplore = () => {
  return (
    <Typography variant='body3' display='flex' alignItems='center' justifyContent='center'>
      <svg width="41" height="2" viewBox="0 0 41 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="40.5" y1="1" x2="0.5" y2="0.999996" stroke="url(#paint0_linear_6284_72872)" />
        <defs>
          <linearGradient id="paint0_linear_6284_72872" x1="40.5" y1="-0.499959" x2="1.7766" y2="-0.499963" gradientUnits="userSpaceOnUse">
            <stop stop-color="#5C5C5C" />
            <stop offset="1" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <Typography variant='body3' lineHeight='15px' mx='8px' color='#5C5C5C'>EXPLORE OTHER FLIGHTS</Typography>
      <svg width="41" height="1" viewBox="0 0 41 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 0C0.223858 0 0 0.223858 0 0.5C0 0.776142 0.223858 1 0.5 1V0ZM0.5 1H40.5V0H0.5V1Z" fill="url(#paint0_linear_6284_72874)" />
        <defs>
          <linearGradient id="paint0_linear_6284_72874" x1="0.5" y1="1.49996" x2="39.2234" y2="1.49996" gradientUnits="userSpaceOnUse">
            <stop stop-color="#5C5C5C" />
            <stop offset="1" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Typography>
  )
}

export default OAExplore;