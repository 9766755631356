import { AppBar, Box, Typography, Skeleton, Button } from '@mui/material'; // Import Skeleton
import { OAButton } from './basic/OAButton';
import { OASwipeableDrawer } from './basic/OASwipeableDrawer';
import { OAFareSummary } from './OAFareSummary';
import { useState } from 'react';
import {
  formatToINR,
  getDiscountPrice,
  getUniqueAirlines,
  getEarnCoin,
  getRsFromCoins,
  getBurnCoin,
} from '../utils';
import { getUserInfo } from '../store/slices/userInfoSlice';
import { getUserInputState } from '../store/slices/userInputSlice';
import OAFlightAvatar from './OAFlightAvatar';
import { useSelector } from '../store';
import { OACoinBanner } from './OACoinBanner';
import OACoinBannerSecondary from './OACoinBannerSecondary';
import { OAImage } from './OAImage';
import { getTotalSelectedSmbPrice } from '../store/slices/flightOrderSlice';

export const OAFooter = ({
  onClick,
  flightData,
  loading,
  priceLoading,
  disabled,
  showPriceBreakup,
  onwardAndReturnData,
  isSearchPage = false,
  displayCoinBanner,
  isDomesticReturn,
  orgCode,
  isBoxShadow = true,
}: {
  onClick: () => void;
  flightData?: any;
  loading?: boolean;
  priceLoading?: boolean;
  disabled?: boolean;
  showPriceBreakup?: boolean;
  onwardAndReturnData?: any;
  isSearchPage?: boolean;
  displayCoinBanner?: boolean;
  isDomesticReturn?: boolean;
  orgCode?: string;
  isBoxShadow?: boolean;
}) => {
  const { coins } = useSelector(getUserInfo);
  const { isCoinsApplied } = useSelector(getUserInputState);
  const totalSelectedSmbPrice = useSelector(getTotalSelectedSmbPrice);
  const totalAmount =
    flightData?.orderAmount?.totalAmount -
    (flightData?.orderAmount?.seatCharges || 0) -
    (flightData?.orderAmount?.mealCharges || 0) -
    (flightData?.orderAmount?.baggageCharges || 0) +
    (totalSelectedSmbPrice || 0);
  const onwardPrice = onwardAndReturnData?.onward?.price?.display;
  const returnPrice = onwardAndReturnData?.return?.price?.display;
  const totalOnwardAndReturnPrice =
    (onwardAndReturnData?.onward?.price?.value || 0) +
    (onwardAndReturnData?.return?.price?.value || 0);
  const discountPrice = getDiscountPrice(
    totalAmount ? totalAmount : totalOnwardAndReturnPrice,
    coins
  );

  const uniqueAirlinesOnward = getUniqueAirlines(onwardAndReturnData?.onward?.segments);
  const uniqueAirlinesReturn = getUniqueAirlines(onwardAndReturnData?.return?.segments);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const burnCoin = getBurnCoin(totalAmount ? totalAmount : (totalOnwardAndReturnPrice ?? 0), coins);
  const coinsToBeEarned = getEarnCoin(flightData?.orderAmount?.totalAmount);
  const isDiscountedPrice = discountPrice < (totalAmount ? totalAmount : totalOnwardAndReturnPrice);
  const earnCoin = getEarnCoin(totalOnwardAndReturnPrice);
  const coinsToBeBurned = getBurnCoin(totalOnwardAndReturnPrice, coins);
  const savedMoney = totalOnwardAndReturnPrice - discountPrice;

  const earnCoinLabel = (
    <>
      <Typography variant="caption">Earn</Typography>
      <Typography mx="4px" fontWeight={600} variant="caption">
        {earnCoin}
      </Typography>
      <Typography variant="caption">Niyo coins on this trip</Typography>
    </>
  );

  return (
    <>
      <Box
        sx={{
          height: displayCoinBanner ? (coins > 0 && isCoinsApplied ? '135px' : '110px') : '75px',
        }}
      ></Box>
      <AppBar
        position="fixed"
        sx={{
          top: 'auto',
          bottom: 0,
          ...(isBoxShadow
            ? {
                boxShadow: orgCode === 'ZOLVE' ? 'revert' : '0px -4px 24px 0px rgba(0, 0, 0, 0.06)',
              }
            : {}),
          ...(!isBoxShadow ? { borderTop: '1px solid #DEE2E1' } : {}),
        }}
      >
        {displayCoinBanner && (
          <>
            {coins > 9 && isDiscountedPrice && isCoinsApplied && (
              <OACoinBanner
                title="Money saved on this trip"
                subTitle={getRsFromCoins(burnCoin)}
                isCurrency={true}
                color="primary"
                sx={{ borderRadius: 0, px: '16px' }}
              />
            )}
            {coinsToBeEarned > 0 && (
              <OACoinBanner
                title="Niyo Coins to be earned on this trip"
                subTitle={coinsToBeEarned}
                isCurrency={false}
                sx={{ borderRadius: 0, px: '16px' }}
              />
            )}
          </>
        )}
        {isSearchPage && isDomesticReturn && !priceLoading && orgCode !== 'ZOLVE' && (
          <OACoinBannerSecondary
            title={
              isCoinsApplied
                ? discountPrice > 0
                  ? coinsToBeBurned > 0 && (
                      <Box display="flex" alignItems="center">
                        Redeeming{' '}
                        <Typography ml="4px" variant="caption" fontWeight={600}>
                          {coinsToBeBurned?.toFixed(2)}
                        </Typography>{' '}
                        <OAImage
                          src="3xNiyo_coin.png"
                          alt="coins"
                          sx={{ mx: '5px', height: '18px', width: '18px' }}
                        />{' '}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body2">to save</Typography>
                          <Typography ml="4px" variant="caption" fontWeight={600}>
                            {formatToINR(savedMoney)}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  : 'Flying free using'
                : discountPrice > 0
                  ? earnCoin > 0
                    ? earnCoinLabel
                    : null
                  : coinsToBeBurned > 0 && (
                      <Box display="flex" alignItems="center">
                        <OAImage src="tick.svg" folder="icons" alt="tick" sx={{ mr: '4px' }} />{' '}
                        Redeeming{' '}
                        <Typography ml="4px" variant="caption" fontWeight={600}>
                          {coinsToBeBurned?.toFixed(2)}
                        </Typography>{' '}
                        <OAImage
                          src="3xNiyo_coin.png"
                          alt="coins"
                          sx={{ mx: '5px', height: '18px', width: '18px' }}
                        />{' '}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body2">to save</Typography>
                          <Typography ml="4px" variant="caption" fontWeight={600}>
                            {formatToINR(savedMoney)}
                          </Typography>
                        </Box>
                      </Box>
                    )
            }
            isEarnCoins={earnCoin > 0}
            isToggleOn={isCoinsApplied}
            color={
              isCoinsApplied ? (coinsToBeBurned > 0 ? 'primary' : '') : earnCoin > 0 ? 'yellow' : ''
            }
            sx={{ borderRadius: 0, px: '16px' }}
          />
        )}
        <Box sx={{ padding: '12px 16px' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              {priceLoading ? (
                <Box display="flex" flexDirection="column">
                  <Skeleton width="100px" height="20px" />
                  <Skeleton width="130px" height="20px" sx={{ mt: '4px' }} />
                </Box>
              ) : (
                <>
                  {flightData?.tripType !== 'ONE_WAY' && isSearchPage && (
                    <Box display="flex" alignItems="center">
                      <Typography variant="caption" display="flex" alignItems="center">
                        <OAFlightAvatar
                          data={uniqueAirlinesOnward}
                          sx={{
                            marginRight: '-8px',
                            position: 'revert',
                            height: '15px',
                            width: '15px',
                          }}
                        />
                        <Typography variant="caption" ml="12px" color="#5A6068">
                          {onwardPrice ?? 'Add onward'} |{' '}
                        </Typography>
                      </Typography>
                      <OAFlightAvatar
                        data={uniqueAirlinesReturn}
                        sx={{
                          marginRight: '-8px',
                          ml: '4px',
                          position: 'revert',
                          height: '15px',
                          width: '15px',
                        }}
                      />
                      <Typography variant="caption" ml="12px" color="#5A6068">
                        {returnPrice ?? 'Add return'}
                      </Typography>
                    </Box>
                  )}
                  <Box display="flex" alignItems="center">
                    {isCoinsApplied && coins > 0 && isDiscountedPrice && (
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', textDecoration: 'line-through' }}
                      >
                        {totalAmount
                          ? formatToINR(totalAmount)
                          : formatToINR(totalOnwardAndReturnPrice)}
                      </Typography>
                    )}
                    <Typography variant="h2" ml={isCoinsApplied && coins > 0 ? '6px' : '0px'}>
                      {isCoinsApplied ? (
                        discountPrice > 0 ? (
                          formatToINR(discountPrice)
                        ) : (
                          <Box sx={{ color: 'success.main' }}>Free</Box>
                        )
                      ) : totalAmount ? (
                        formatToINR(totalAmount)
                      ) : (
                        formatToINR(totalOnwardAndReturnPrice)
                      )}
                    </Typography>
                  </Box>
                </>
              )}
              {!priceLoading && showPriceBreakup && (
                <Button
                  disableRipple
                  sx={{
                    padding: 0,
                    minWidth: 0,
                    minHeight: 0,
                  }}
                  onClick={toggleDrawer} // Attach the same toggle function
                >
                  <Typography
                    variant="body3"
                    sx={{ color: 'primary', textDecoration: 'underline' }}
                  >
                    View breakup
                  </Typography>
                </Button>
              )}
            </Box>
            {priceLoading ? (
              <Skeleton
                variant="rectangular"
                width="120px"
                height="50px"
                sx={{ borderRadius: '24px' }}
              />
            ) : (
              <OAButton
                variant="contained"
                color="secondary"
                onClick={onClick}
                loading={loading}
                disabled={disabled}
              >
                {isSearchPage ? 'Proceed' : 'Continue'}
              </OAButton>
            )}
            <OASwipeableDrawer
              title="Fare summary"
              open={isOpen}
              isContainer={false}
              onOpen={toggleDrawer}
              onClose={toggleDrawer}
            >
              <OAFareSummary data={flightData} />
            </OASwipeableDrawer>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};
