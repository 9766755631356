import React, { useCallback, useMemo } from 'react';
import { Box, Card } from '@mui/material';
import { IFlight } from '../../../type';
import AirlineDetail from './AirlineDetail';
import FlightDetail from './FlightDetail';
import { OAImage } from '../../../components';
import { useSelector } from '../../../store';
import { getUserInputState } from '../../../store/slices/userInputSlice';

const FlightCard = ({
  data,
  onClick,
  tripType,
  isInternational,
  orgCode,
  nearbyAirports,
  sx
}: {
  data: IFlight;
  onClick: (flight: IFlight) => void;
  tripType: 'R' | 'O';
  isInternational?: boolean;
  orgCode?: string;
  nearbyAirports?: any;
  sx?: any
}) => {
  const domesticReturn = tripType === 'R' && !isInternational;
  const { travelDetail } = useSelector(getUserInputState);

  const airportReviewData = useMemo(() => {
    const onwardSegments = data?.segments?.filter((segment: any) => segment.departureType === 'ONWARD');
    const returnSegments = data?.segments?.filter((segment: any) => segment.departureType === 'RETURN');

    const onwardDeparture = onwardSegments?.[0]?.departure?.airport?.iata;
    const onwardArrival = onwardSegments?.[onwardSegments.length - 1]?.arrival?.airport?.iata;

    const returnDeparture = returnSegments?.[0]?.departure?.airport?.iata;
    const returnArrival = returnSegments?.[returnSegments.length - 1]?.arrival?.airport?.iata;

    const searchedFrom = travelDetail?.from?.iata || '';
    const searchedTo = travelDetail?.to?.iata || '';

    const isDifferentOnwardDeparture = onwardDeparture !== searchedFrom;
    const isDifferentOnwardArrival = onwardArrival !== searchedTo;
    const isDifferentReturnDeparture = returnDeparture !== searchedTo;
    const isDifferentReturnArrival = returnArrival !== searchedFrom;

    if (isDifferentOnwardDeparture || isDifferentOnwardArrival || 
        ((isDifferentReturnDeparture || isDifferentReturnArrival) && travelDetail?.tripType === 'R')) {
      return {
        isDifferentOnwardDeparture,
        isDifferentOnwardArrival,
        isDifferentReturnDeparture,
        isDifferentReturnArrival,
        onwardDeparture,
        onwardArrival,
        returnDeparture,
        returnArrival,
        searchedFrom,
        searchedTo,
        tripType: travelDetail?.tripType,
      };
    }
    return null;
  }, [data, travelDetail]);

  const handleClick = useCallback(() => onClick(data), [onClick, data]);

  const cardStyle = useMemo(() => ({
    p: '1px',
    borderRadius: domesticReturn ? '0px' : '8px',
    border: domesticReturn ? 0 : '1px solid #DEE2E1',
    bgcolor: domesticReturn ? 'transparent' : '#FDFDFD',
    position: 'relative',
    overflow: 'unset',
    ...sx
  }), [domesticReturn, sx]);

  return (
    <Card sx={cardStyle}>
      <Box onClick={handleClick}>
        <AirlineDetail data={data} tripType={tripType} isInternational={isInternational} orgCode={orgCode} />
        <FlightDetail 
          data={data} 
          tripType={tripType} 
          isInternational={isInternational} 
          airportReviewData={airportReviewData}
          nearbyAirports={nearbyAirports}
          orgCode={orgCode}
        />
      </Box>
    </Card>
  );
};

export default FlightCard;
