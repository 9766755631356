import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { OAHeader, OASwitch } from '../../components';
import { HeaderActionFlight } from './FlightHeaderAction';
import { useDispatch, useSelector } from '../../store';
import RecentFlightSearch from './RecentFlightSearch';
import { getUserInfo, setLatLong, setMock } from '../../store/slices/userInfoSlice';
import SearchParameters from './SearchParameters';
import { isMobileWebview, parseObject, isTestEmailForProd } from '../../utils';
import { getAuthInfo } from '../../store/slices/authSlice';
import { useGetBookingsListQuery } from '../../services/bookingsApi';
import CoinsBanner from './CoinsBanner';
import { setIsCoinsApplied } from '../../store/slices/userInputSlice';
import OffersBenefits from './OffersBenefits';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import CoinBannerZolve from './CoinBannerZolve';
import SpecialFlights from './SpecialFlights';
import { useAirportDataQuery } from '../../services/airportDataApi';

const VERSION = '4.3.28';
const Flight = () => {
  const dispatch = useDispatch();
  const { user, campaigns, token } = useSelector(getAuthInfo);
  const { data } = useGetBookingsListQuery({}, { skip: !user });
  const { isMock } = useSelector(getUserInfo);
  const { coins } = useSelector(getUserInfo);
  const [scrollTop, setScrollTop] = useState<boolean>(true);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const { data: aggregatesData, isLoading } = useAirportDataQuery({}, { skip: !user });
  const params = new URLSearchParams(window.location.search);
  const organizationCode = params.get('orgCode') ?? user?.organizationCode;
  const isBannerDisplayed = useMemo(
    () => burnCoinsFeat || campaigns?.eligibleCampaigns?.length > 0,
    [burnCoinsFeat, campaigns]
  );

  useEffect(() => {
    if (coins > 9) {
      dispatch(setIsCoinsApplied(true));
    }
  }, [coins]);

  useEffect(() => {
    if (isMobileWebview() && window?.niyo_location && typeof window.niyo_location === 'function') {
      console.log('eventLocation called!!!');

      window.niyo_location().then((res: any) => {
        console.log('eventLocation promised!!!', res);

        const resParse = parseObject(res);
        dispatch(setLatLong(resParse));
      });
    }
    console.log(`Version ${VERSION}`);
  }, []);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setMock(event.target.checked));
    },
    [dispatch]
  );

  const renderBgImg =
    organizationCode === 'ZOLVE' || window.location.hostname === 'flights.zolve.com'
      ? 'https://oa-flight-assets.s3.ap-south-1.amazonaws.com/banner_zolve_home.png'
      : 'https://oa-flight-assets.s3.ap-south-1.amazonaws.com/icons/banner_zolve_home.png';

  return (
    <Box>
      <OAHeader
        title={''}
        appBarSx={{
          borderBottom: 'none',
          backgroundImage: `url(${renderBgImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: organizationCode === 'ZOLVE' ? '100%' : 'cover',
          backgroundPosition: organizationCode === 'ZOLVE' ? 'top' : 'center',
          ...(organizationCode === 'ZOLVE'
            ? {}
            : {
              minHeight:
                window?.innerHeight === 667 && window?.innerWidth === 375 ? '255px' : '350px',
            }),
          position: 'relative', // Added position relative
          width: '100%',
        }}
        action={<HeaderActionFlight />}
        backgroundImageText={organizationCode === 'ZOLVE' ? <CoinBannerZolve /> : <CoinsBanner />}
      />
      {aggregatesData?.data?.exclusiveFlights && <Box mt='-38px' position='relative' zIndex={1000}>
        <SpecialFlights specialFlights={aggregatesData?.data?.exclusiveFlights || []} />
      </Box>}
      <Container
        sx={{
          borderRadius: '20px 20px 0px 0px',
          position: 'relative',
          ...(organizationCode === 'ZOLVE' ? {} : { mt: '-35px' }),
          mt: '2px',
          backgroundColor: 'white', // Ensure that the container background is white to overlay
        }}
      >
        <SearchParameters shouldFetchLocations={true} setScrollTop={setScrollTop} />
        {/* <Box mt="30px">
          <CoinsBanner />
        </Box> */}
      </Container>
      {isBannerDisplayed && <OffersBenefits />}
      <Box
        sx={{
          mb: '30px',
          mt: isBannerDisplayed ? 0 : '30px',
        }}
      >
        <RecentFlightSearch data={aggregatesData} isLoading={isLoading} />
      </Box>

      {isTestEmailForProd(user?.email) && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            textAlign: 'center',
            mt: '24px',
            p: '10px',
            color: 'grey.800',
            bgcolor: 'grey.500',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
          }}
        >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Version {VERSION}
          </Typography>

          <Box onClick={() => navigator.clipboard.writeText(token)} ml="24px">
            (Token)
          </Box>

          <Box ml="24px">
            <OASwitch onChange={handleOnChange} checked={isMock} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Flight;
