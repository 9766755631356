import { Box, Typography } from '@mui/material';

const CoinBannerZolve = () => {
  return (
    <Box
      sx={{
        padding: '10px 0 60px 20px',
      }}
    >
      <Typography variant="h2" sx={{ color: 'text.primary', fontSize: '30px', lineHeight: '40px' }}>
        Flights
      </Typography>
      <Box mt="4px">
        <Box display="flex" alignItems="center">
          <Typography variant="body1">Get</Typography>
          <Typography ml="6px" variant="body1" color="primary.main">
            exclusive discounted
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body1">
            student only fares at Zolve
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CoinBannerZolve;
