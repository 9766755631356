import React, { useMemo, useRef, useState } from 'react';
import { Grid, Box, Card, CardContent, Typography } from '@mui/material';
import FlightCard from './FlightCard';
import { IFlight } from '../../type';
import { FixedSizeList, VariableSizeList } from 'react-window';
import { useSelector } from '../../store';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getUserInfo } from '../../store/slices/userInfoSlice';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getUserInputState } from '../../store/slices/userInputSlice';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import FlightLabel from './FlightLabel';
import { OAImage, OASwipeableDrawer } from '../../components';
import OAZolveExclusivePlug from '../../components/zolve/OAZolveExclusivePlug';
import OAExplore from '../../components/zolve/OAExplore';
interface FlightsListProps {
  flights: IFlight[];
  onFlightSelect: (flight: IFlight, type?: string) => void;
  tripType: 'R' | 'O';
  isInternational: boolean;
  selectedFlight: IFlight;
  fastestFlight: IFlight;
  lowestPriceFlight: IFlight;
  isFastestAndLowestSame: boolean;
  nearbyAirports: any;
}

const style = {
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none', // Hides scrollbar for WebKit browsers (Chrome, Safari, etc.)
  },
  '-ms-overflow-style': 'none', // Hides scrollbar for IE and Edge
  'scrollbar-width': 'none', // Hides scrollbar for Firefox
};

const FlightsList: React.FC<FlightsListProps> = ({
  flights,
  onFlightSelect,
  tripType,
  isInternational,
  fastestFlight,
  lowestPriceFlight,
  isFastestAndLowestSame,
  nearbyAirports,
}) => {
  const posthog = usePostHog();
  const listRef = useRef<VariableSizeList>(null);
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const { campaigns } = useSelector(getAuthInfo);
  const [selectedGroup, setSelectedGroup] = useState<IFlight[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [cardHeights, setCardHeights] = useState<{ [key: number]: number }>({});
  const [zolveExclusiveFlights, setZolveExclusiveFlights] = useState<IFlight[]>([]);

  const handleSimilarFlightClick = (group: IFlight[], tripType: 'R' | 'O') => {
    setSelectedGroup(group);
    setOpenDrawer(true);
  };

  const { similarFlights, unsimilarFlights } = useMemo(() => {
    const groupedFlights: { [key: string]: IFlight[] } = {};
    const zolveExclusive: IFlight[] = [];

    flights?.forEach(flight => {
      const isZolveExclusive = flight?.price?.fareTags?.some((tag: any) => tag?.type === 'ZOLVE_EXCLUSIVE');
      if (isZolveExclusive) {
        zolveExclusive?.push(flight);
      } else {
        if (flight?.segments && flight?.segments?.length > 0) {
          const airlineCode = flight?.segments[0]?.airline?.code;
          const key = `${airlineCode}-${flight?.price?.value}`;
          if (!groupedFlights[key]) {
            groupedFlights[key] = [];
          }
          groupedFlights[key].push(flight);
        }
      }
    });
    const similar = Object?.values(groupedFlights)?.filter(group => group?.length > 1);
    const similarFlightIds = new Set(similar?.flat()?.map(flight => flight?.id));

    const unsimilar = flights?.filter(
      flight => !similarFlightIds?.has(flight?.id) && !flight?.price?.fareTags?.some((tag: any) => tag?.type === 'ZOLVE_EXCLUSIVE')
    );

    setZolveExclusiveFlights(zolveExclusive);

    return { similarFlights: similar, unsimilarFlights: unsimilar };
  }, [flights]);

  const isInternationalAndRoundTrip = isInternational && tripType === 'R';
  const burningCoins = coins > 9 && isCoinsApplied && burnCoinsFeat;

  const isDomesticOrInternationalOneWay = tripType === 'O' && (isInternational || !isInternational);

  const handleCloseBottomDrawer = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker({
      flowName: 'Flight',
      screenName: 'SRP',
      ctaAction: 'SRP_MoreOptionsClose',
      screenDuration: totalScreenDuration?.toString(),
      UserAction: {
        userAction: 'User has closes the bottom sheet (tap out/close icon)'
      }
    }, posthog)
    setOpenDrawer(false)
  }

  const Row = ({ index, style }: any) => {
    const totalFlights = zolveExclusiveFlights?.length + similarFlights?.length + unsimilarFlights?.length;
  
    let flight: IFlight | null = null;
    let isSimilar = false;
  
    if (index < totalFlights) {
      if (index < zolveExclusiveFlights?.length) {
        flight = zolveExclusiveFlights[index];
      } else if (index < zolveExclusiveFlights?.length + similarFlights?.length) {
        const similarGroupIndex = index - zolveExclusiveFlights?.length;
        const similarGroup = similarFlights[similarGroupIndex];
        if (similarGroup && similarGroup.length > 0) {
          flight = similarGroup[0];
          isSimilar = true;
        }
      } else {
        const unsimilarIndex = index - zolveExclusiveFlights?.length - similarFlights?.length;
        flight = unsimilarFlights[unsimilarIndex];
      }
    }
  
    if (!flight) return null;
  
    return (
      <div style={style}>
        <Box
          key={flight?.id}
          sx={{ marginBottom: '15px' }}
          pt="10px"
          ref={(el: HTMLDivElement | null) => {
            if (el) {
              const height = el.getBoundingClientRect().height;
              if (height !== cardHeights[index]) {
                setCardHeights(prev => ({ ...prev, [index]: height }));
                if (listRef.current) {
                  listRef.current.resetAfterIndex(index);
                }
              }
            }
          }}
          position='relative'
        >
          <FlightCard
            data={flight}
            onClick={() => onFlightSelect(flight!)}
            tripType={tripType}
            isInternational={isInternational}
            nearbyAirports={nearbyAirports}
            sx={{ borderBottomRightRadius: isSimilar ? "0px" : undefined }}

          />
          {zolveExclusiveFlights?.some((exclusiveFlight) => exclusiveFlight?.id === flight?.id) && (
            <FlightLabel
              bgColor='#D44333'
              icon='zolve-logo.svg'
              text='Zolve exclusive'
              folder='zolveIcons'
              customStyles={{ zIndex: 99, right: '16px' }}
            />
          )}
          
          {isSimilar && similarFlights[index - zolveExclusiveFlights?.length]?.length > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white',
                  padding: '4px 8px',
                  paddingRight: '3px',
                  borderRadius: '4px',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  zIndex: 1000,
                  bgcolor: '#FF6633',
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                  borderTopRightRadius: '0px',
                  borderTopLeftRadius: '0px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => {
                  const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                  eventsTracker({
                    flowName: 'Flight',
                    screenName: 'SRP',
                    ctaAction: 'SRP_MoreOptions',
                    screenDuration: totalScreenDuration.toString(),
                    UserAction: {
                      userAction: 'User has tapped on the green button to open the bottomsheet',
                    },
                  }, posthog);
                  handleSimilarFlightClick(similarFlights[index - zolveExclusiveFlights?.length], tripType);
                }}
              >
                <Typography variant='body4' color='white'>
                  +{similarFlights[index - zolveExclusiveFlights?.length]?.length - 1} same price options
                </Typography>
                <OAImage src='right-chevron.svg' folder='icons' alt='right' width='16px' height='16px' />
              </Box>
            </Box>
          )}
        </Box>
        {index === zolveExclusiveFlights?.length - 1 && (
        <Box pt='9px' mb='24px'>
          <OAExplore />
        </Box>
      )}
      </div>
    );
  };
  
  return (
    <Grid container>
      {zolveExclusiveFlights?.length > 0 && <Box mb='17px'>
        <OAZolveExclusivePlug />
      </Box>}
      <Grid item xs={12} sx={{ height: '100%', overflowY: 'auto' }} mb="35px" pt={zolveExclusiveFlights ? '12px' : 'revert'}>
        {isInternational && tripType === 'R' ? (
          <FixedSizeList
            style={style}
            height={1500}
            width="100%"
            itemSize={
              campaigns?.eligibleCampaigns?.length > 0 ||
                (isCoinsApplied && coins > 0 && burnCoinsFeat)
                ? 225
                : 195
            }
            itemCount={flights?.length}
          >
            {Row}
          </FixedSizeList>
        ) : (
          <>
            {zolveExclusiveFlights?.map((exclusiveFlight) => (
              <Box position='relative' key={exclusiveFlight?.id} sx={{ marginBottom: '15px' }}>
                <FlightCard
                  data={exclusiveFlight}
                  onClick={() => onFlightSelect(exclusiveFlight)}
                  tripType={tripType}
                  isInternational={isInternational}
                  nearbyAirports={nearbyAirports}
                />
                <FlightLabel
                  bgColor='#D44333'
                  icon='zolve-logo.svg'
                  text='Zolve exclusive'
                  folder='zolveIcons'
                  customStyles={{ zIndex: 99, right: '16px', py: '1px !important' }}
                />
              </Box>
            ))}
            {zolveExclusiveFlights?.length > 0 && <Box pt='9px' pb='12px'><OAExplore /></Box>}
            {similarFlights?.map((similarCollection: any, index: number) => (
              <Box
                mt='8px'
                mb="20px"
              >
                <Card
                  sx={{ zIndex: 3, p: 0, border: 'none', borderBottomRightRadius: '0px' }}
                  onClick={() => onFlightSelect(similarCollection[0])}>
                  <CardContent sx={{ p: '0 !important' }}>
                    <FlightCard
                      data={similarCollection[0]}
                      onClick={() => { }}
                      tripType={tripType}
                      isInternational={isInternational}
                      sx={{ borderBottomRightRadius: "0px" }}
                      nearbyAirports={nearbyAirports}
                    />
                  </CardContent>
                </Card>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  onClick={() => {
                    const totalScreenDuration = Math?.floor((Date?.now() - startTime) / 1000);
                    eventsTracker({
                      flowName: 'Flight',
                      screenName: 'SRP',
                      ctaAction: 'SRP_MoreOptions',
                      screenDuration: totalScreenDuration?.toString(),
                      UserAction: {
                        userAction: 'User has tapped on the green button to open the bottomsheet'
                      }
                    }, posthog)
                    handleSimilarFlightClick(similarCollection, 'O')
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#FF6633',
                      color: 'white',
                      padding: '4px 8px',
                      paddingRight: '3px',
                      borderRadius: '4px',
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      zIndex: 1000,
                      bgcolor: '#FF6633',
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                      borderTopRightRadius: '0px',
                      borderTopLeftRadius: '0px',
                      display: 'flex',
                    }}
                    onClick={() => {
                      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                      eventsTracker({
                        flowName: 'Flight',
                        screenName: 'SRP',
                        ctaAction: 'SRP_MoreOptions',
                        screenDuration: totalScreenDuration?.toString(),
                        UserAction: {
                          userAction: 'User has tapped on the green button to open the bottomsheet'
                        }
                      }, posthog)
                      handleSimilarFlightClick(similarCollection, 'O')
                    }}
                  >
                    <Typography variant='body4' color='white'>+{similarCollection?.length - 1} same price options</Typography>
                    <OAImage src='right-chevron.svg' height="16px" width="16px" folder='icons' alt='right' />
                  </Box>
                </Box>
              </Box>
            ))}
            {unsimilarFlights?.map((flight: any) => (
              <Box key={flight?.id} sx={{ marginBottom: '15px' }}>
                <FlightCard
                  data={flight}
                  onClick={() => onFlightSelect(flight)}
                  tripType={tripType}
                  isInternational={isInternational}
                  nearbyAirports={nearbyAirports}
                />
              </Box>
            ))}
          </>
        )}
      </Grid>
      <OASwipeableDrawer
        open={openDrawer}
        onClose={handleCloseBottomDrawer}
        onOpen={() => setOpenDrawer(true)}
        title="Same price options"
        isContainer={false}
        maxHeight={90}
      >
        <Box>
          {selectedGroup?.map((flight) => (
            <Box key={flight?.id} sx={{ marginBottom: '15px' }} px='16px' mt='8px'>
              {(flight?.id === fastestFlight?.id) && (flight?.id === lowestPriceFlight?.id) ? (
                <>
                  <FlightLabel
                    bgColor='#215039'
                    icon='percent.svg'
                    text='Lowest Price'
                    customStyles={{ top: '90px', zIndex: 99, right: '90px' }}
                  />
                  <FlightLabel
                    bgColor='#F0935F'
                    icon='fastest.svg'
                    text='Fastest'
                    customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                  />
                </>
              ) : (
                <>
                  {lowestPriceFlight && flight?.id === lowestPriceFlight?.id && (
                    <FlightLabel
                      bgColor='#215039'
                      icon='percent.svg'
                      text='Lowest Price'
                      customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                    />
                  )}
                  {fastestFlight && flight?.id === fastestFlight?.id && isInternational && (
                    <FlightLabel
                      bgColor='#F0935F'
                      icon='fastest.svg'
                      text='Fastest'
                      customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                    />
                  )}
                </>
              )}
              <FlightCard
                data={flight}
                onClick={() => {
                  const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                  eventsTracker({
                    flowName: 'Flight',
                    screenName: 'SRP',
                    ctaAction: 'SRP_MoreOptionsSelect',
                    screenDuration: totalScreenDuration?.toString(),
                    UserAction: {
                      userAction: 'User has selected a flight from the bottom sheet'
                    }
                  }, posthog)
                  onFlightSelect(flight)
                }}
                tripType={tripType}
                isInternational={isInternational}
                nearbyAirports={nearbyAirports}
              />

            </Box>
          ))}
        </Box>
      </OASwipeableDrawer>
    </Grid>
  );
};

export default FlightsList;
