import { createApi } from "@reduxjs/toolkit/query/react";
import { baseApi } from "./baseApi";
import { AIRPORT_SEARCH, NEARBY_AIRPORT, RECENT_FLIGHT_SEARCH } from "../store/apiUrls";

export const airportDataApi = createApi({
  reducerPath: 'airportDataApi',
  baseQuery: baseApi,
  endpoints: (builder) => ({
    airportData: builder.query({
      query: () => ({ 
        url: RECENT_FLIGHT_SEARCH, 
        method: 'GET'
      }),
    }),
    airportSearch: builder.mutation({
      query: (query) => ({ 
        url: `${AIRPORT_SEARCH}?query=${query}`, 
        method: 'GET', 
      }),
    }),
    nearbyAirport: builder.query({
      query: () => ({ 
        url: NEARBY_AIRPORT, 
        method: 'GET'
      }),
    }),
  })
});

export const { useAirportDataQuery, useAirportSearchMutation, useLazyNearbyAirportQuery } = airportDataApi;