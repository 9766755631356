import { Card, Typography } from "@mui/material";
import { OAImage } from "../../components";

const NearbyAirportNudge = ({ 
  nudgeText 
} : {
  nudgeText: string
}) => {
  return (
    <Card sx={{ mt: '10px', display: 'flex', p: '10px' }}>
      <OAImage sx={{
        alignSelf: 'flex-start',
      }} src="location-dot.svg" alt="location" folder="icons" />
      <Typography ml='10px' variant='body3'>{nudgeText}</Typography>
    </Card>
  )
}

export default NearbyAirportNudge;