import React from 'react';
import { Box } from '@mui/material';
import { OAEmpty, OAImage, OALoadingSearch, OALoadingSearchLottie } from '../../components';
import FlightCardSkeleton from './FlightCard/FlightCardSk';
import FlightsList from './FlightsList';
import ReturnsFlightList from './ReturnFlightsList';

const FlightContent = ({
  isError,
  error,
  refetch,
  isLoading,
  isUpdatingResults,
  isResultsLoading,
  flights,
  flightOptions,
  tripType,
  isInternational,
  filter,
  selectedFlight,
  handleFlightSelect,
  toggleEditDrawer,
  handleClearFilter,
  orgCode,
  nearbyAirports
}: any) => {
  if (isError) {
    return isInvalidDateError(error) ? (
      <OAEmpty
        bodytext="Invalid date. Try a new search."
        buttonText="Edit Search"
        onClickBtn={toggleEditDrawer}
      />
    ) : (
      <OAEmpty onClickBtn={refetch} error={error} orgCode={orgCode} />
    );
  }

  if (isLoading || isUpdatingResults || isResultsLoading) {
    return orgCode === 'ZOLVE' ? (
      <OALoadingSearchLottie />
    ) : (
      <OALoadingSearch />
      // <FlightCardSkeleton tripType={tripType} isInternational={isInternational} count={10} />
    );
  }

  if (
    filter &&
    !flightOptions?.onwardFlightOptions?.length &&
    !flightOptions?.returnFlightOptions?.length
  ) {
    return (
      <OAEmpty
        illustration={
          <OAImage
            src={orgCode === 'ZOLVE' ? 'no-results-found.svg' : 'person-with-phone.svg'}
            folder={orgCode === 'ZOLVE' ? 'zolveErrorAssets' : 'niyoErrorAssets'}
            alt="empty"
          />
        }
        bodytext="No flights are available for this route"
        buttonText="Clear Filter"
        onClickBtn={handleClearFilter}
      />
    );
  }

  const onwardFlights = flights?.data?.results?.onward || [];

  const lowestPrice = Math.min(...onwardFlights?.map((flight: any) => flight?.price?.value));

  // Find all flights with the lowest price
  const lowestPriceFlights = onwardFlights?.filter((flight: any) => flight?.price?.value === lowestPrice);
  
  // Determine if there is a unique lowest price flight
  const uniqueLowestPriceFlight = lowestPriceFlights?.length === 1 ? lowestPriceFlights[0] : null;

  // Find the shortest duration
  const shortestDuration = Math.min(...onwardFlights?.map((flight: any) => flight?.durationInfo?.seconds));

  // Find all flights with the shortest duration
  const shortestDurationFlights = onwardFlights?.filter((flight: any) => flight?.durationInfo?.seconds === shortestDuration);

  // Determine if there is a unique fastest flight
  const uniqueFastestFlight = shortestDurationFlights?.length === 1 ? shortestDurationFlights[0] : null;
  
  const isFastestAndLowestSame = uniqueFastestFlight && uniqueLowestPriceFlight && uniqueFastestFlight?.id === uniqueLowestPriceFlight?.id;

  if (flights?.data?.results?.onward?.length) {
    return (
      <Box>
        {tripType === 'O' || isInternational ? (
          <FlightsList
            flights={flightOptions?.onwardFlightOptions}
            onFlightSelect={handleFlightSelect}
            tripType={tripType}
            isInternational={isInternational}
            selectedFlight={selectedFlight?.onward}
            fastestFlight={uniqueFastestFlight}
            lowestPriceFlight={uniqueLowestPriceFlight}
            isFastestAndLowestSame={isFastestAndLowestSame}
            nearbyAirports={nearbyAirports}
          />
        ) : (
          <ReturnsFlightList
            flightOptions={flightOptions}
            selectedFlight={selectedFlight}
            tripType={tripType}
            isInternational={isInternational}
            onSelect={handleFlightSelect}
            handleClearFilter={handleClearFilter}
            orgCode={orgCode}
          />
        )}
      </Box>
    );
  }

  return (
    <OAEmpty
      illustration={
        <OAImage
          src={orgCode === 'ZOLVE' ? 'no-results-found.svg' : 'person-with-phone.svg'}
          folder={orgCode === 'ZOLVE' ? 'zolveErrorAssets' : 'niyoErrorAssets'}
          alt="empty"
        />
      }
      bodytext="No flights are available for this route"
      buttonText="Edit Search"
      onClickBtn={toggleEditDrawer}
    />
  );
};

const isInvalidDateError = (error: any) => {
  return error?.data?.errors?.some((e: any) => e.field === 'tripStart.date');
};

// export default FlightContent;
export default React.memo(FlightContent, (prevProps, nextProps) => {
  return (
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.isUpdatingResults === nextProps.isUpdatingResults &&
    prevProps.isResultsLoading === nextProps.isResultsLoading &&
    prevProps.isError === nextProps.isError &&
    prevProps.tripType === nextProps.tripType &&
    prevProps.isInternational === nextProps.isInternational &&
    prevProps.filter === nextProps.filter &&
    prevProps.selectedFlight === nextProps.selectedFlight &&
    JSON.stringify(prevProps.flightOptions) === JSON.stringify(nextProps.flightOptions)
  );
});
