import { Box, Typography } from "@mui/material";

interface ChipWithIconProps {
  icon: string;
  label: string;
  onClick?: () => void;
}

const ChipWithIcon: React.FC<ChipWithIconProps> = ({
  icon,
  label,
  onClick,
}) => {
  return (
    <Box display="flex" alignItems="center" gap="5px" onClick={onClick}>
      <img
        src={`https://oa-flight-assets.s3.ap-south-1.amazonaws.com/${icon}.svg`}
        alt="icon"
        style={{ width: '20px', height: '20px' }}
      />
      <Typography variant="body3">
        {label}
      </Typography>
    </Box>
  )
}

export default ChipWithIcon;