import { Box, Card, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getFormatedDate } from "../../utils";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../store';
import { setTravelDetail } from '../../store/slices/userInputSlice';

interface Segment {
  id: string;
  price: {
    display: string;
  };
  departureType: "ONWARD" | "RETURN";
  segmentId: number;
  airline: {
    code: string;
    name: string;
    flightNumber: string;
  };
  departure: {
    airport: {
      id: string;
      iata: string;
      name: string;
      city: string;
    };
    date: string;
    time: string;
  };
  arrival: {
    airport: {
      id: string;
      iata: string;
      name: string;
      city: string;
    };
    date: string;
    time: string;
  };
  flightDuration: {
    display: string;
    seconds: number;
    min: number;
  };
  stops: {
    value: number;
    display: string;
  };
}

const SpecialFlights = ({ specialFlights }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCardClick = (flight: any) => {
    const onwardDeparture = flight.segments[0]?.departure;
    const returnArrival = flight?.segments[flight?.segments?.length - 1]?.arrival;

    const flightDetails = {
      origin: onwardDeparture?.airport?.city,
      originCode: onwardDeparture?.airport?.iata,
      destination: returnArrival?.airport?.city,
      destinationCode: returnArrival?.airport?.iata,
      date: onwardDeparture?.date,
    };

    const payload = {
      tripType: "O",
      cabinType: "ECONOMY",
      travellerCount: { adult: "1" },
      from: { iata: flightDetails.originCode, city: flightDetails.origin },
      to: { iata: flightDetails.destinationCode, city: flightDetails.destination },
      tripStart: { date: flightDetails.date },
      fareGroup: "STUDENT"
    };

    dispatch(setTravelDetail(payload));

    const tripType = 'O';
    const cabinType = payload.cabinType;
    const traveller = `adults=${payload.travellerCount.adult}`;
    const fromTo = `${payload.from.iata}&fromCity=${payload.from.city}&to=${payload.to.iata}&toCity=${payload.to.city}&fromDate=${payload.tripStart.date}&fareGroup=${payload.fareGroup}`;
    const searchURL = `results?tripType=${tripType}&cabin=${cabinType}&${traveller}&from=${fromTo}`;

    navigate(searchURL);
  };

  return (
    <Box display='flex' justifyContent='space-between' gap='16px' overflow='auto' px='20px'>
      {specialFlights?.onward?.map((flight: any, index: number) => (
        <Card
          key={`flight-${index}`}
          onClick={() => handleCardClick(flight)}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '4px',
            overflow: 'auto',
            width: 'auto',
            flex: '0 0 auto',
          }}
        >
          <Box display='flex' flexDirection='column' gap='2px'>
            <Typography variant="body3" color='#D44333' mb='-4px'>{flight?.seatCount} seats left</Typography>
            <Typography fontWeight={500}>
              {flight?.segments[0]?.departure?.airport?.city} ({flight?.segments[0]?.departure?.airport?.iata}) - {flight?.segments[flight?.segments?.length - 1]?.arrival?.airport?.city} ({flight?.segments[flight?.segments?.length - 1]?.arrival?.airport?.iata})
            </Typography>
            <Typography variant="body3" lineHeight='16px' color='text.secondary'>{flight.durationInfo?.display} • Oneway • {flight.stops?.display}</Typography>
            <Typography variant="body3" lineHeight='16px' color='text.secondary'>{getFormatedDate(flight.segments[0]?.departure?.date, 'MMM dd, yyyy')}</Typography>
          </Box>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center' gap='4px'>
              <Typography fontWeight={600}>{flight?.price?.display}</Typography>
              <Typography variant="body3" color='text.secondary' sx={{ textDecoration: 'line-through' }}>
                {flight?.price?.strikeOffPriceDisplay}
              </Typography>
            </Box>
            <Box py='3px' px='10px' display='flex' alignItems='center' justifyContent='center' borderRadius='48px' bgcolor='primary.main' >
              <ArrowForwardIcon sx={{ height: '18px', width: '18px', color: 'white' }} />
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  );
};

export default SpecialFlights;