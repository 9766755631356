import { Box, Typography } from "@mui/material";
import { OAImage } from "../../components";

interface FlightLabelProps {
  bgColor: string;
  icon: string;
  text: string;
  customStyles?: object;
  folder?: string;
}

const FlightLabel: React.FC<FlightLabelProps> = ({ bgColor, icon, folder='icons', text, customStyles }) => {
  return (
    <Box
      bgcolor={bgColor}
      sx={{
        position: 'absolute',
        top: '-10px',
        right: '16px',
        borderRadius: '18px',
        px: '5px',
        py: '3px',
        display: 'flex',
        alignItems: 'center',
        ...customStyles,
      }}
    >
      <OAImage folder={folder} src={icon} alt={text} />
      <Typography
        variant='body4'
        fontWeight='500'
        color='#FDFDFD'
        ml='2px'
      >
        {text}
      </Typography>
    </Box>
  );
};

export default FlightLabel;